@import './themes/pages/app/sass/forms';

html,
body,
body > #root {
    height: 100%;
}

#root {
    overflow-y: scroll;
}

body {
    margin: 0;
    font-size: 13px;
    padding-right: 0 !important;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body > #root {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
}

*:focus {
    outline-color: #ffff33;
}
